(function ($) {

	var RacingRecord = function () {

		this.events = {};
		this.id = 0;
		var self = this;

		this.hexToRgb = function (hex) {

			if(hex.indexOf('rgba') > -1){
				return  'rgb' + hex.slice(4, -4) + ')';
			}

			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
			var components = result ? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
			} : null;
			if (!components) { return null; }
			return 'rgb(' + components.r.toString() + ', ' + components.g.toString() + ', ' + components.b.toString() + ')';
		};

		this.dataFor = function (element) {
			var data = {
				values: [],
				labels: [],
				marker: { colors: [] },
				textposition: 'none',
				hoverinfo: 'none',
				type: 'pie',
				direction: 'clockwise',
				sort: false
			};
			var chart = element.attr('data-racing-record-chart');
			var hasValue = false;
			$('[data-chart-point="' + chart + '"]').each(function (index, point) {
				var name = $(point).attr('data-name');
				var color = $(point).siblings('[data-color]').css('background-color');
				var value = $(point).attr('data-value');
				if (!name || !value) { return; }
				if (value !== '0') { hasValue = true; } // Data defaults to zero.
				data.values.push(value);
				data.labels.push(name);
				data.marker.colors.push(color);
			});

			if (!hasValue) { return false; }
			return data;
		};

		this.initFor = function (element) {
			element.html('');
			var data = self.dataFor(element);
			if (!data) {
				element.addClass('empty-center').append($('<span>No Data Available</span>'));
				return;
			}
			var chart = element.attr('data-racing-record-chart');
			element.removeClass('empty-center').attr('id', chart);
			Plotly.newPlot(element.attr('id'), [self.dataFor(element)], {
				showlegend: false,
				height: 300,
				width: 300,
				margin: {
					b: 0,
					l: 0,
					r: 0,
					t: 0,
					autoexpand: false
				}
			}, {
				displayModeBar: false,
				displaylogo: false
			});
			$('svg', element).each(function (index, svg) {
				svg.setAttribute('viewBox', '0 0 300 300');
				svg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
			});
			$('svg .slice .surface', element).each(function (index, surface) {
				// Find the point related to this surface (by color).
				var point = false;
				var color = self.hexToRgb($(surface).css('fill'));
				if (!color) {
					color = $(surface).css('fill');
				}
				$('[data-chart-point="' + chart + '"]').parents('.stat').find('.line').each(function (index, chartPointLine) {
					if ($(chartPointLine).css('background-color') === color) {
						point = $(chartPointLine).parents('.stat');
					}
				});


				if (!point) { return; }

				// Add a data element to the stat pointing back to this surface.
				var surfaces_raw = point.attr('data-surfaces');
				var surfaces = [];
				if (surfaces_raw) {
					surfaces = surfaces_raw.split(',');
				}
				self.id++;
				$(surface).attr('id', 'surface-' + self.id);
				surfaces.push(self.id);
				point.attr('data-surfaces', surfaces.join(','));

				// Listen to the hover events.
				$(surface).hover(function () {
					self.events.statHoverIn(point);
				}, function () {
					self.events.statHoverOut(point);
				});

			});
		};


		this.events.statHoverIn = function (element) {
			$(element).addClass('hover');
			var surfaces = $(element).attr('data-surfaces');
			if (!surfaces) { return; }
			$.each(surfaces.split(','), function (index, id) {
				var $surface = $('#surface-' + id);
				$surface.attr('class', 'surface hover');
				$surface.parents('.trace').attr('class', 'trace hover');
			});
		};

		this.events.statHoverOut = function (element) {
			$(element).removeClass('hover');
			var surfaces = $(element).attr('data-surfaces');
			if (!surfaces) { return; }
			$.each(surfaces.split(','), function (index, id) {
				var $surface = $('#surface-' + id);
				$surface.attr('class', 'surface');
				$surface.parents('.trace').attr('class', 'trace');
			});
		};

	};

	RacingRecord.prototype.init = function () {

		var self = this;
		$('[data-racing-record-chart]').each(function (index, element) {
			self.initFor($(element));
		});

		$('.stat[data-show]').each(function (index, element) {
			$(element).hover(function () {
				self.events.statHoverIn(element);
			}, function () {
				self.events.statHoverOut(element);
			});
		});

	};

	window.ABR.RacingRecord = new RacingRecord();

})(jQuery);
