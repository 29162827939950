$(document).ready(function () {

	var trackMapEnaabledPages = ['/tracks'];

	// Variable for keeping track of the last active marker position
	var activeMarkerPosition = null;

	/**
	 * This function take a position and sets it at the center of the map.
	 * Creates the map with a greyscale style.
	 *
	 * @returns {*}
	 */
	function initializeMap() {
		// Sets the style to greyscale
		var styles = [
			{
				stylers: [
					{hue: "#000000"},
					{saturation: -100}
				]
			}, {
				featureType: "road",
				elementType: "geometry",
				stylers: [
					{lightness: 100},
					{visibility: "simplified"}
				]
			}, {
				featureType: "road",
				elementType: "labels",
				stylers: [
					{visibility: "off"}
				]
			}
		];

		// Set map options to country level zoom centered on the USA with no map type controls.
		// Also disable map dragging and scroll zooming.
		var mapOptions = {
			zoom: baseZoom,
			center: center,
			// mapTypeId: "style1",
			mapTypeControlOptions: {
				mapTypeIds: []
			},
			//draggable: false,
			scrollwheel: false
		};

		// Create map with options above

		var map = new google.maps.Map(document.getElementById("map"), mapOptions);

		// Set the map style
		map.setOptions({styles: styles});
		return map;
	}

	/**
	 * This function is intended to retrieve the track information formatted into an object.
	 * The current implementation returns a hardcoded example set of tracks.
	 * @returns {*[]}
	 */
	function retrieveTrackInformation() {

		$.ajaxSetup({"async": false});

		var trackData = [];

		$.getJSON("/api/node.json?parameters[type]=track&fields=nid,title,latitude,longitude,signature,address,body,image&pagesize=1000", function (data) {

			data.forEach(function (value) {

				//var address1 = value.address ? value.address.

				if (value.latitude && value.longitude && value.title) {

					var locality = value.address.locality ? value.address.locality : '';
					var thoroughfare = value.address.thoroughfare ? value.address.thoroughfare : '';
					var administrativeArea = value.address.administrative_area ? value.address.administrative_area : '';
					var postalCode = value.address.postal_code ? value.address.postal_code : '';
					var bodyText = value.body.value ? value.body.value : '';
					var image = value.image.file ? value.image.file.styles.medium_thumbnail_16_9 : '';

					var track = {
						latLng: new google.maps.LatLng(value.latitude, value.longitude),
						name: value.title,
						description: bodyText,
						address1: thoroughfare,
						address2: locality + ' ' + administrativeArea + ', ' + postalCode,
						directionsLink: 'https://www.google.com/maps/dir/Current+Location/' + value.latitude + ',' + value.longitude,
						featured: value.signature,
						image: image
					};


					trackData.push(track);

				}
			});
		});

		$.ajaxSetup({"async": true});

		return trackData;
	}

  /**
   * This function takes a marker data object and an optional selected parameter.
   * It returns the appropriate image to use as the icon.
   *
   * @param marker
   * @param selected
   * @returns {*}
   */
  function getIconForMarker(marker, selected) {
    // Set the default value of selected to false
    selected = typeof selected !== 'undefined' ? selected : false;

    // If the marker is featured, use the red icons. If not, use blue.
    if (marker.featured === true) {
      if (selected) {
        return 'sites/all/themes/custom/abr_theme/img/icons/pin-red-lg.png';
      } else {
        return 'sites/all/themes/custom/abr_theme/img/icons/pin-red.png';
      }
    } else {
      if (selected) {
        return 'sites/all/themes/custom/abr_theme/img/icons/pin-blue-lg.png';
      } else {
        return 'sites/all/themes/custom/abr_theme/img/icons/pin-blue.png';
      }
    }
  }

	/**
	 * This function takes a map and associates all the necessary markers from the marker data object
	 *
	 * @param map
	 * @param markerData
	 */
	function initMarkers(map, markerData) {

		// Declare new markers array
		var newMarkers = [];

		// Declare marker variable
		var marker;

		for (var i = 0; i < markerData.length; i++) {

			var image = getIconForMarker(markerData[i]);

			marker = new google.maps.Marker({
				map: map,
				draggable: false,
				position: markerData[i].latLng,
				visible: true,
				icon: image
			});

			// Creates an infoBox div to add content to
			var boxText = document.createElement("div");

			// Configure the infobox options to set the position above the location,
			// styles, close url, and alignment
			var infoboxOptions = {
				content: boxText,
				disableAutoPan: false,
				maxWidth: 0,
				pixelOffset: new google.maps.Size(25, -40),
				zIndex: null,
				boxStyle: {
					//background: "url('http://google-maps-utility-library-v3.googlecode.com/svn/trunk/infobox/examples/tipbox.gif') no-repeat",
					width: "272px"
				},
				closeBoxMargin: "10px",
				closeBoxURL: "/sites/all/themes/custom/abr_theme/img/icons/popup-x.svg",
				infoBoxClearance: new google.maps.Size(20, 20),
				isHidden: false,
				pane: "floatPane",
				enableEventPropagation: false,
				alignBottom: false
			};

			// Add the new marker to the new marker array
			newMarkers.push(marker);

			// Define the text and style for all infoboxes
			boxText.style.cssText = "border: 1px solid black; background:#FFF; color:#FFF; font-family: 'Source Sans Pro', sans-serif, 'Helvetica Neue', Helvetica, Roboto; font-size:12px; padding: 5px; border-radius:6px; -webkit-border-radius:6px; -moz-border-radius:6px;";

			// Set the inner content of the info box with information from the marker data object
			boxText.innerHTML = "<div class='infoBoxContent'>"
				+ "<div class='topContent'>"
				+ "<h1 class='name'>"
				+ markerData[i].name
				+ "</h1>"
				+ "<span class='location'>"
				+ markerData[i].address1
				+ "<br />"
				+ markerData[i].address2
				+ "</span>"
				+ "</div>"
				+ "<img class='trackLogo' src='" + markerData[i].image + "' />"
				+ "<div class='bottomContent'>"
				+ markerData[i].description.substring(0, 80) + '...'
				+ "</div>"
				+ "</div>"
				+ "<div class='row'>"
				+ "<a class='small-6 columns trackButton' href='tracks/" + markerData[i].name.replace(/ +/g, '-').toLowerCase() + "'>"
				+ "Track Info"
				+ "</a>"
				+ "<a class='small-6 columns trackButton' target='_blank' href='" + markerData[i].directionsLink + "'>"
				+ "Directions"
				+ "</a>"
				+ "</div>"
				+ "</div>"
				+ "</div>";

			// Create and associate the infobox with on of the new markers
			newMarkers[i].infobox = new InfoBox(infoboxOptions);

			// Add event listener to close other markers and open the selected one.
			google.maps.event.addListener(marker, 'click', (function (marker, i) {
				return function () {
					// Close open markers
					for (var j = 0; j < newMarkers.length; j++) {
						newMarkers[j].infobox.close();
						newMarkers[j].setIcon(getIconForMarker(markerData[j]));
					}

					// Open selected marker
					newMarkers[i].infobox.open(map, this);

					// On open, set the active marker position
					activeMarkerPosition = marker.getPosition();

					map.setCenter(marker.getPosition());
					newMarkers[i].setIcon(getIconForMarker(markerData[i], true));
				};
			})(marker, i));

			google.maps.event.addListener(newMarkers[i].infobox, 'closeclick', (function (marker, i) {
				return function () {
					newMarkers[i].infobox.close();
					newMarkers[i].setIcon(getIconForMarker(markerData[i]));

					// On close, reset the active marker position
					activeMarkerPosition = null;
				};
			})(marker, i));

		}


		// Close all markers on map click
		google.maps.event.addListener(map, 'click', function () {
			for (var i = 0; i < newMarkers.length; i++) {
				newMarkers[i].infobox.close();

				newMarkers[i].setIcon(getIconForMarker(markerData[i]));
			}

			// On close, reset the active marker position
			activeMarkerPosition = null;
		});
	}

  /**
   * Provide error details about location issues
   *
   * @param browserHasGeolocation
   * @param infoWindow
   * @param pos
   */
  function handleLocationError(browserHasGeolocation, infoWindow, position) {
    infoWindow.setPosition(position);
    infoWindow.setContent(browserHasGeolocation ?
      'Error: The Geolocation service failed.' :
      'Error: Your browser doesn\'t support geolocation.');
  }

  /**
   * This function is intended to init the info window when first needed to prevent an empty info window appear before its content is set
   * @returns {*}
   */
  function getInfowindow() {
    if (infoWindow == null) {
      infoWindow = new google.maps.InfoWindow({map: map});
    }
    return infoWindow;
  }

	/**
	 * This function sets up listeners on the top form items.
	 * This function establishes a click listener for fetching current geolocation,
	 * a form listener for fetching location information via ajax, and a map reset listener.
	 */
	function initializeTopControls(map) {
		var input = /** @type {!HTMLInputElement} */(document.getElementById('locationInput'));
		var autocomplete = new google.maps.places.Autocomplete(input);
		autocomplete.bindTo('bounds', map);

		autocomplete.addListener('place_changed', function () {
			if (autocomplete.getPlace().address_components) {
				$("#locationForm").submit();
			}
		});

		// Configures browser geolocation options
		$('#gpsLocation').on('click', function () {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function (position) {
					var p = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};

					// Center the map and zoom in
					map.setCenter(p);
					map.setZoom(baseZoom + zoomModifier);
				}, function () {
					handleLocationError(true, infoWindow, map.getCenter());
				});
			} else {
				// Browser doesn't support Geolocation
				handleLocationError(false, infoWindow, map.getCenter());
			}
		});

		// Configures reset option to return to original view of map
		$('#resetLocation').on('click', function () {
			map.setCenter(center);
			map.setZoom(baseZoom);

			infoWindow = getInfowindow();

			if (infoWindow) {
				infoWindow.close();
			}
		});

		/**
		 * On form submit, center the map on the google geocode match for the entered location and set info flag.
		 */
		$("#locationForm").on('submit', function (e, input) {

			// Prevent default submission of the form
			e.preventDefault();

			// Make an ajax request to the google geocode api endpoint with the input location value
			$.ajax({
					url: "https://maps.googleapis.com/maps/api/geocode/json?address=" + $("#locationInput").val(),
					beforeSend: function (xhr) {
						xhr.overrideMimeType("text/plain; charset=x-user-defined");
					}
				})
				.done(function (data) {
					// Parse the returned json for the location lat and lng values
					data = $.parseJSON(data);

					if (!data.results[0]) {
						alert('That location was not found.  Please try again.');
						return;
					}

					var position = {
						lat: data.results[0].geometry.location.lat,
						lng: data.results[0].geometry.location.lng
					};

					// Get the infoWindow
					infoWindow = getInfowindow();

					// Set it's position and message, and display the info window
					infoWindow.setPosition(position);
					infoWindow.setContent('Location found');
					infoWindow.open(map);

					// Center the map on the new position and zoom as needed
					map.setCenter(position);
					map.setZoom(baseZoom + zoomModifier);
				});
		});
	}

	/**
	 * Centers the map around either the active marker or the center of the map on window resize
	 */
	function centerMap(center, map, activeMarkerPosition) {
		if (activeMarkerPosition != null) {
			map.setCenter(activeMarkerPosition);
		} else {
			map.setCenter(center);
		}
	}

	if (trackMapEnaabledPages.indexOf(window.location.pathname) > -1) {

		// Set the center of the map
		var center = new google.maps.LatLng(38.0, -95.0);

		var baseZoom = 4;

		var zoomModifier = 1;

		// Create the map and set the center location


		var map = initializeMap();

		// Declare the info window variable
		var infoWindow = null;

		// Declare and set the track information to set markers with
		var trackLocations = retrieveTrackInformation();

		//var infoWindow = new google.maps.InfoWindow({map: map});


		// Configure the markers from the provided track locations
		initMarkers(map, trackLocations);

		// Initialize top location controls
		initializeTopControls(map);

		$(window).on('resize orientationChange', function () {
			centerMap(center, map, activeMarkerPosition);
		});
	}
});
