(function($) {

	var MediaOverlay = function () {
		var self = this;
		self.events = {};
		self.methods = {};
		var overlay, mediaContainer, close;

		self.events.init = function () {
			overlay = $("[data-media-overlay]").first();
			mediaContainer = $(".media-container", overlay).first();
			close = $("[data-media-overlay] a.close").first();
			close.click(self.events.closeClick);
		};

		self.events.click = function (e) {
			e.preventDefault();
			self.methods.showOverlay($(this).attr("href"), $(this).attr("data-overlay"));
		};

		self.events.closeClick = function (e) {
			e.preventDefault();
			self.methods.closeOverlay();
			mediaContainer.html("");
		};

		self.methods.showOverlay = function (mediaUrl, type) {
			if (overlay.hasClass("visible")) {
			  return;
			}
			switch (type) {
				case "image":
					self.methods.embedImage(mediaUrl);
					break;
				case "video":
					self.methods.embedVideo(mediaUrl);
					break;
			}
			overlay.addClass("visible");
			$(document).trigger("scroll-overlay");
		};

		self.methods.closeOverlay = function () {
			overlay.removeClass("visible");
			$(document).trigger("scroll-overlay");
		};

		self.methods.embedImage = function (url) {
			mediaContainer.html("<img src=\"" + url + "\" />");
		};

		self.methods.embedVideo = function (url) {
      var youtubeId = (url.includes("="))? url.split("=")[1]: url;
      mediaContainer.html("<iframe width=\"100%\" height=\"100%\" frameborder=\"0\" src=\"https://www.youtube.com/embed/" + youtubeId + "?autoplay=1&enablejsapi=1\" allow=\"accelerometer; autoplay; encrypted-media; gyroscope\" allowfullscreen></iframe>");
		};
	};

	MediaOverlay.prototype.init = function () {
		var self = this;
		$("a[data-overlay]:not([data-overlay-handled])").each(function (index, element) {
			var $element = $(element);
			$element.click(self.events.click);
			$element.attr("data-overlay-handled", true);
		});
		self.events.init();
	};

	window.ABR.MediaOverlay = new MediaOverlay();
})(jQuery);
