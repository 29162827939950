(function($) {

	var Sticky = function () {

		var self = this;

		// This top offset option is for the administration menu and the
		// mobile administration menu when they're enabled. The top offset
		// is set after initializing the sticky class to keep the modularity
		// in tact.
		this.topOffset = 0;
		this.lastTopOffset = null;

		// Internal variable to store the offset height.
		this.offsetHeight = 0;
		this.bodyHeight = 0;

		// Internal variable to determine whether or not global events
		// have been attached.
		this.attached = false;

		// Determine whether or not debug mode is on.
		this.debug = false;

		// Store the scroll direction in this variable.
		this.direction = 'down';

		// Determine the offset of the footer.
		this.footerOffset = 0;

		// Variable to store the directory element.
		this.directorySticky = false;
		this.directoryMargins = 0;
		// NOTE: Storing the variable like this means we only support
		// displaying one directory sticky at once. DO NOT STICK MULTIPLE.

		// Variable to store the document height.
		this.windowHeight = 0;

		this.container = null;
		this.stickies = [];
		this.events = {};
		this.methods = {};
		this.options = {

			// If stuck is true, the element will always be stuck to the top
			// of the page. Padding will be added to the top of the body content
			// to make up for the space taken up at the top of the page.
			stuck: false,

			// The height can either be auto-calculated, or you can specify the
			// height using data attributes.
			height: false,

			// The replace group for the sticky.
			replace: null,

			// A selector for the parent of the element. Whenever this is set, the
			// element becomes sticky whenever its parent is visible on the page
			// and it needs to be stuck.
			parent: null,

			// The Foundation breakpoint that the page needs to at least be on for
			// the parent logic to function.
			parentBreakpoint: ''

		};

		var $document = $(document);

		this.events.resize = function () {

			// Recalculate all the heights.
			$.each(self.stickies, function (index) {
				self.methods.calculateHeights(self.stickies[index]);
			});

			// Recalculate everything else.
			self.calculate();

		};

		this.events.scroll = function () {

			// There was logic here to use requestAnimationFrame. That seemed
			// to introduce a performance degradation, so we have removed it.
			// If you want to add it back in the future, check the svn logs.

			// Call the regular update method.
			self.events.update();

			// Update the maximum height of the directory if it exists.
			if (self.directorySticky !== false) {
				self.methods.updateDirectoryMaxHeight();
			}

		};

		this.events.update = function () {

			// Get the scrollTop.
			var topOfPage = $document.scrollTop() + self.offsetHeight;

			// Adjust the position of the debug element.
			if (self._debugElement) {
				self._debugElement.css('top', topOfPage);
			}

			// For each of the scroll elements, determine if we should stick them.
			$.each(self.stickies, function (index, sticky) {

				// We don't care about stuck ones.
				if (sticky.options.stuck) { return; }

				// If the element doesn't have an owner document, skip it.
				// Performance optimization: we used to check for visibility,
				// but removed it because it's very slow.
				if (!sticky.element[0].ownerDocument.documentElement) { return; }

				// Get the offset.
				var offset = sticky.element.offset();

				// If we don't have an offset, or we have an error, return.
				if (!offset) { return; }

				// --- Here begins the actual sticking/unsticking logic ---

				// If the element is not stuck and the top of it is above
				// the bottom of the current sticky header and is within the
				// confines of its parent if it has one, stick it.
				if (!sticky._stuck && offset.top <= topOfPage) {
					self.methods.stick(sticky);
					return; // Return so we don't get into a loop of sticking and unsticking.
				}

				// If the element is stuck and the top of the placeholder is below
				// the bottom of the current header, unstick it.
				var stickyHeight = sticky._height;
				if (sticky._previousMember && sticky._previousMember._stuck) {
					stickyHeight -= sticky._previousMember._height;
				}
				if (sticky._stuck && sticky.placeholder.offset().top + stickyHeight >= topOfPage) {
					self.methods.unstick(sticky);
				}

			});

		};

		this.methods.updateDirectoryMaxHeight = function () {

			// Get the window offset.
			// footer offset - window offset.
			// if thats greater than window height, make it window height.
			// add sticky header offset to it -> you get available height.
			// subtract the margin top, margin bottom of the sticky height
			// from that. make that the max height.

			// Footer offset - window offset.
			var available = self.footerOffset - window.pageYOffset;

			// If that's greater than window height, make it window height.
			if (available > self.windowHeight) {
				available = self.windowHeight;
			}

			// Subtract the sticky height to get the available height.
			available -= self.bodyHeight;

			// Subtract margin top, margin bottom of the sticky height from
			// that. Make that the max height.
			available -= self.directoryMargins;
			if (available < 10) {
				self.directorySticky.hide();
			} else {
				self.directorySticky.show();
				self.directorySticky.css('max-height', available);
			}

		};

		this.methods.calculateHeights = function (sticky) {
			sticky._height = sticky.element.outerHeight();
		};

		this.methods.stick = function (sticky) {

			// If it's already stuck, do nothing.
			if (sticky._stuck) { return; }
			sticky._stuck = true;

			// TODO: Implement replace logic.

			// If we don't have a placeholder, generate one.
			if (!sticky.placeholder) {
				sticky.placeholder = $('<div class="abr-sticky-placeholder"></div>');
				sticky.placeholder.insertBefore(sticky.element);
			}

			// Calculate the height of the element for the placeholder.
			sticky.placeholder.css('min-height', sticky._height);

			// Insert the placeholder before the element and move the element to the
			// bottom of the sticky header.
			sticky.placeholder.show();
			sticky.element = sticky.element.detach().appendTo(self.container);
			sticky.element.removeClass('abr-unstuck').addClass('abr-stuck');

			// Calculate the new height.
			self.calculate();

		};

		this.methods.unstick = function (sticky) {

			// If it's already unstuck, do nothing.
			if (!sticky._stuck) { return; }
			sticky._stuck = false;

			// If we don't have a placeholder, do nothing.
			if (!sticky.placeholder) { return; }

			// Hide the placeholder and insert the original element after it.
			sticky.element = sticky.element.detach().insertAfter(sticky.placeholder);
			sticky.placeholder.hide();
			sticky.element.removeClass('abr-stuck').addClass('abr-unstuck').show();

			// TODO: Handle previous member logic.

			// Calculate the new height.
			self.calculate();

		};

	};

	Sticky.prototype.calculate = function () {

		// Determine the height to prepend to the body.
		var height = 0;
		var bodyHeight = 0;
		$.each(this.stickies, function (index, sticky) {

			if (!sticky._height || !sticky._stuck) { return; }
			if (sticky.options.stuck) {
        bodyHeight += sticky._height;
      }
			if (!sticky._frozen && sticky.element.is(':visible')) {
        height += sticky._height;
      }
		});

		// Update the body's prepended value.
		jss.remove('body:before');
		jss.set('body:before', {
			'min-height': bodyHeight.toString() + 'px'
		});

		// Update the top margin of the sticky container.
		if (this.container && this.topOffset !== this.lastTopOffset) {
			this.lastTopOffset = this.topOffset;
			this.container.css('margin-top', this.topOffset);
		}
		if (this.topOffset) {
      this.offsetHeight = height + this.topOffset;
    } else {
      this.offsetHeight = height;
    }

		// Update the body height.
		this.bodyHeight = bodyHeight;

	};

	Sticky.prototype.calculateDirectoryVariables = function () {

		// Update the height of the footer.
		var footer = $('[data-abr-sticky-footer]').first();
		if (footer.length) {
			this.footerOffset = footer ? footer.offset().top : $(document).height();
		}

		// Check to see if we've got a directory sticky element.
		this.directorySticky = $('.directory-sticky-outer[data-abr-sticky] > .inner');
		if (this.directorySticky) {
			this.directorySticky.css({overflow: 'auto', 'box-sizing': 'border-box'});
			this.directoryMargins = this.directorySticky.outerHeight(true) - this.directorySticky.outerHeight(false);
		}

		// Recalcualate the height of the document.
		this.windowHeight = $(window).height();

		// Trigger the scroll event again.
		this.events.scroll();

	};

	Sticky.prototype.setHeight = function (selector, height) {

		// Update the height of the sticky.
		$.each(this.stickies, function (index, sticky) {
			if (sticky.element.is(selector)) {
				sticky._height = height;
			}
		});

		// Then do some calculations.
		this.calculate();

	};

	Sticky.prototype.remove = function (selector) {

		var self = this;

		// Prepare an array of indexes to remove.
		var toRemove = [];
		$.each(this.stickies, function (index, sticky) {
			if (sticky.element.is(selector)) {
				sticky.element.remove();
				toRemove.push(index);
			}
		});

		// Sort and reverse the array because the indexes are recalculated
		// every time we remove an element, so we'll want to start from the
		// highest index and go to the lowest index.
		toRemove.sort().reverse();
		$.each(toRemove, function (index, removeIndex) {
			self.stickies.splice(removeIndex, 1);
		});
		self.calculate();

	};

	Sticky.prototype.init = function () {

		var self = this;

		// Attach events if we haven't already.
		if (!self.attached) {
			self.attached = true;
			$(window).on('scroll', self.events.scroll);
			$(window).on('resize', Foundation.util.throttle(self.events.resize, 100));
			setInterval(self.calculateDirectoryVariables.bind(self), 50);
		}

		// If debug mode is on, create the debug element.
		if (self.debug) {
			self._debugElement = $('<div style="width: 200px; height: 10px; background: #000; position: absolute; top: 0; left: 0; z-index: 9999;"></div>');
			$('body').append(self._debugElement);
		}

		// Add the sticky container to the top of the page if we need one.
		var sticky_elements = $('[data-abr-sticky]');
		if (sticky_elements.length > 0) {
			if (!self.container) {
				$('body').prepend('<div class="abr-sticky-container" data-abr-sticky-container></div>');
				self.container = $('[data-abr-sticky-container]');
			}
		}

		// Initialize the individual sticky elements.
		sticky_elements.not('.handled').addClass('handled').each(function (index, element) {

			var sticky = { element: $(element) };
			sticky.options = $.extend({}, self.options, $(element).data());

			// If the sticky element is stuck to the top of the page, move it there.
			if (sticky.options.stuck) {
				self.container.append(sticky.element.detach());
				sticky._stuck = true;
			}

			// If we have a height, set the height.
			if (typeof(sticky.options.height) !== 'undefined' && sticky.options.height !== false) {
				sticky._height = sticky.options.height;
				sticky._outerHeight = sticky.options.height;
			} else {
				sticky._height = sticky.element.outerHeight();
				sticky._outerHeight = sticky.element.outerHeight(true);
			}

			// Add the sticky.
			self.stickies.push(sticky);

		});

		// Recalculate the various values.
		self.calculate();

	};

	window.ABR.Sticky = new Sticky();

})(jQuery);
