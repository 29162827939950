// Put JS that gets executed before all other files here.
if (typeof(window.ABR) !== 'object') {
	window.ABR = {};
}

const popupCookie = 'NewsletterPopup';
var iframeLoad = 0;

// Little helper to calculate administration menu heights.
jQuery(window).bind('resize', function () {
	if (typeof(ABR.AdminMenuLoaded) === 'function') {

		var slicknav = jQuery('.slicknav_menu');
		var admin_menu = jQuery('#admin-menu');
		var height = 0;
		if (slicknav.is(':visible')) {
			height = slicknav.outerHeight();
		} else if (admin_menu.is(':visible')) {
			height = admin_menu.outerHeight();
		}

		ABR.AdminMenuLoaded(height);
	}
});

jQuery(document).bind('infiniteScrollComplete', function() {
  Opentip.findElements();
});

// Let's be a little hacky about disabling collapsibles on medium up.
if (typeof(Drupal.CTools) !== 'undefined' &&
	typeof(Drupal.CTools.CollapsibleCallbacks) !== 'undefined') {
	Drupal.CTools.CollapsibleCallbacks.push(function ($container, handle, content, toggle) {
		if (Foundation.MediaQuery.atLeast('medium') && !content.parents('.panel-pane').first().hasClass('pane-node-disqus')) {
			content.slideToggle = function (time, callback) {
				// Lol, nope.
			};
		} else {
			content.slideToggle = jQuery.fn.slideToggle;
		}
	});
}
