$(document).foundation();

// Initialize scroll.
function adminMenuHeights() {
	var slicknav = $('.slicknav_menu');
	var admin_menu = $('#admin-menu');
	if (slicknav.is(':visible')) {
		return slicknav.outerHeight();
	} else if (admin_menu.is(':visible')) {
		return admin_menu.outerHeight();
	}
}
function headerHeight(expanded) {
	var medium = window.ABR.Medium;
	if (medium && expanded) { return 110; }
	else if (medium && !expanded) { return 63; }
	else if (!medium) { return 53; }
}

var body = $('body');

var articleHeaderScrolled = false;
var bodySubnavVisible = true;
ABR.Scroll.scrollUpImmediate.push(function (st) {
	if (!bodySubnavVisible && $(document).height() - window.ABR.WindowHeight - st > 100) {
		bodySubnavVisible = true;
		body.addClass('subnav-visible');
		ABR.Sticky.setHeight('header', headerHeight(true));
	}

});

ABR.Scroll.scrollDownImmediate.push(function (st) {
	if (st > 100 && !articleHeaderScrolled) {
		articleHeaderScrolled = true;
		$('.top-bar.article-header').addClass('scrolled');
	}
	if (st > 100 && bodySubnavVisible) {
		bodySubnavVisible = false;
		body.removeClass('subnav-visible');
		ABR.Sticky.setHeight('header', headerHeight(false));
	}
});
ABR.Scroll.scrollUp.push(function () {
	ABR.Sticky.direction = 'up';
});
ABR.Scroll.scrollDown.push(function () {
	ABR.Sticky.direction = 'down';
});
ABR.Scroll.scrollBottom.push(function () {
	if ($(window).height() < $(document).height()) {
		bodySubnavVisible = false;
		$('body').removeClass('subnav-visible');
		ABR.Sticky.setHeight('header', headerHeight(false));
  }
	$('[data-scrollbottom-notif]').click();
});
ABR.Scroll.scrollTop.push(function () {
	bodySubnavVisible = true;
	$('body').addClass('subnav-visible');
	ABR.Sticky.setHeight('header', headerHeight(true));
	$('.top-bar.article-header').removeClass('scrolled');
	articleHeaderScrolled = false;
});
ABR.Scroll.init();

// Initialize the progress.
ABR.Progress.init();

// Initialize the ad manager.
ABR.Ads.init();

// Initialize expandable.
ABR.Expandable.init();

// Initialize the racing record.
ABR.RacingRecord.init();

// Initialize the scrollTo.
ABR.ScrollTo.init();

// Initialize the menu.
ABR.Menu.init();

// Initialize fancy checkbox.
ABR.FancyCheckbox.init();
$(document).on('abr-fancycheckbox', function () {
	ABR.FancyCheckbox.fancy();
});

// Initialize the image loader.
ABR.ImageLoader.init();

// Initialize the link select.
ABR.LinkSelect.init();

// Initialize the media overlay.
ABR.MediaOverlay.init();

// Initialize the infinite loader.
ABR.Infinite.init();

// Scroll overlay events.
$(document).on('scroll-overlay', function () {
	var top = window.pageYOffset;
	var overlay = $('[data-scroll-overlay].visible');
	if (overlay.length > 0) {
		var shouldContinue = false;
		overlay.each(function (index, element) {
			var breakpoint = $(element).attr('data-scroll-overlay');
			if (breakpoint && !Foundation.MediaQuery.atLeast(breakpoint)) {
				shouldContinue = true;
			} else if (!breakpoint) {
				shouldContinue = true;
			}
		});
		if (shouldContinue) {
			$('html').addClass('noscroll');
			$('body').css('top', -1 * top)
				.data('top', top);
			return;
		}
	}

	// If we haven't already set the noscroll, unset it.
	$('html').removeClass('noscroll');
	$(window).scrollTop($('body').data('top'));
});

$('html').on('on.zf.toggler', function () {
	$('.sidebar-overlay').addClass('visible');
	$(document).trigger('scroll-overlay');
}).on('off.zf.toggler', function () {
	$('.sidebar-overlay').removeClass('visible');
	$(document).trigger('scroll-overlay');
});

// Initialize the sticky container with the resize handler.
ABR.Sticky.init();
ABR.Sticky.topOffset = adminMenuHeights();
ABR.Sticky.calculate();
$(window).on('resize', Foundation.util.throttle(function () {
	ABR.Sticky.topOffset = adminMenuHeights();
	ABR.Sticky.calculate();
}, 150));
$(document).on('abr-sticky', function () {
	ABR.Sticky.init();

});

// Initialize the admin menu loaded handler.
ABR.AdminMenuLoaded = function (height) {

	// Update sticky.
	ABR.Sticky.topOffset = height;
	ABR.Sticky.calculate();

};

// Initialize parallax.
var parallax = $('.parallax');
var photo_credits = $('.caption-credit', parallax);
if (parallax.length > 0) {
	$(window).scroll(function (e) {
		var scrolled = window.pageYOffset;
		var offset = scrolled * 0.2;
		parallax.css('transform', 'translate3d(0px,' + offset.toString() + 'px, 0px)');
		photo_credits.css('transform', 'translate3d(0px, -' + offset.toString() + 'px, 0px');
	});
}

// Select.js
if ($('.styled-select').length) {
	var selectInstance = new Select({
		el: document.querySelector('select.styled-select'),
		className: 'select-theme-default',
		useNative: false
	});
}

$(function () {
	// Show/Hide logic for top four leaderboard
	$('#leaderboard-top-four').change(function () {
		var topSelectList = $('#leaderboard-top-four option:selected');

		var value = topSelectList.val();
		var theDiv = $('#' + value);

		theDiv.removeClass('hide');
		theDiv.siblings().addClass('hide');

		// Change view tab to selected leaderboard option
		var tabToShow = topSelectList.val().replace('top-', '');

		// Remove active class from all and add only to active
		$('.tabs-title a').attr('aria-selected', 'false');

		$('ul.tabs li.tabs-title').removeClass('is-active');

		$('#tab-' + tabToShow + '-label')
			.attr('aria-selected', 'true')
			.parent().addClass('is-active');

		$('.tabs-panel').removeClass('is-active');
		$('#tab-' + tabToShow).addClass('is-active');
	});

	// Change selected value in top leaderboard select
	$('#mini-panel-leaderboard_views .tabs-title a').on('click', function () {
		selectInstance.change($(this).attr('aria-controls').replace('tab', 'top'));
	});

	// Trigger the change if we have a specific fragment.
	if (window.location.hash && typeof selectInstance !== 'undefined') {
		selectInstance.change('top-' + window.location.hash.replace('#', ''));
	}
});

// Initialize the share.
new Share();

// Set the medium breakpoint.
$(window).on('resize', Foundation.util.throttle(function () {
	window.ABR.WindowHeight = $(window).height();
	window.ABR.Medium = Foundation.MediaQuery.atLeast('medium');
}, 100));
window.ABR.Medium = Foundation.MediaQuery.atLeast('medium');
window.ABR.WindowHeight = $(window).height();

// If replay exists in the query string and we have a race replay,
// jump to it and play it.
if (window.location.href.indexOf('?replay=1') !== -1) {
	setTimeout(function () {
		var $player = $('.abr_video_player');
		$.scrollTo($player, {
			offset: -200,
			duration: 250,
			onAfter: function (target, settings) {
				$player.click();
			}
		});
	}, 1000);
}

// When the search link is clicked, show the sidebar and focus on the
// search form.
$('#search-link').click(function () {
	$('html').foundation('toggle');
	$('#sidebar .search-form .form-item input[type="text"]').focus();
});

// Responsive video
$(document).ready(function(){
	// Target your .container, .wrapper, .post, etc.
	var embed = $('.media_embed');
	if (typeof(embed.fitVids) !== 'undefined') {
		$(".media_embed").fitVids();
	}

	//Homepage video image replace with embed
  $('.hp-video').click(function(e){
    if ($(this).data('video')){
      //dont' follow url, play inline
      e.preventDefault();
      var video = $(this).data('video');
      //hide the details if they are shown to allow controls to work.
      $(this).parent().find('.details').hide();
      if (video.includes('youtube')) {
        $(this).html($('<iframe>', {
          'width': '100%',
          'height': '100%',
          'frameborder': '0',
          'allowfullscreen': '',
          'allow': 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
          'src': video + '?autoplay=1&enablejsapi=1'
        }));
      } else {
        //Add video if it hasn't been added.
        // If it is already available play or pause it
        if(!$.contains($(this).get(0), $(this).find('video').get(0))) {
          $(this).html($('<video>', {
            'autoplay': '',
            'controls': '',
            'width': '100%',
            'height': '100%'
          }).prepend($('<source>', {
            'type': 'video/mp4',
            'src': video})));
        } else {
          var video = $(this).find('video').get(0);
          video.paused? video.play() : video.pause();
        }
      }
    }
  });

	$('.slick-arrow, .flex-container').click(function(){
	  //stop any playing videos
    $('.hp-video').each(function(){
      $(this).find('iframe').attr('src', $(this).data('video'));
      $(this).find('video').trigger('pause');
    });
  });

});

// Sidebar overlay toggle (default click events were not working for some reason,
// so we must override them here).
$('.sidebar-overlay').on('touchend', function () {
	$('html').foundation('toggle');
});

// Firefox doesnt like percentage widths with flex items: stackoverflow.com/questions/33502702/flex-elements-ignore-percent-padding-in-firefox
function resizeTriangles() {
	$('.triangle').each(function (i, elem) {
		var width = $(this).width();
		var padding = width * 0.12;
		$(this).css('paddingBottom', padding + 'px');
	});
}
$(document).ready(resizeTriangles);
$(window).on('resize', Foundation.util.throttle(resizeTriangles, 150));

// Make sure sponsor images inside the header register their click events.
$('.section-heading .sponsor a').click(function (e) {
	e.stopPropagation(); // Stop propagation so the parents don't handle the event.
});

window.ABR.ReOpenTip = function () {
	Opentip.findElements();
	$('[data-ot]').attr('data-ot', null);
};

// Article-based events.
window.ABR.ArticleLoaded = function (scope) {

	// Initialize foundation.
	if (scope !== document) {
		$(scope).foundation();
	}

	// Responsive video.
	var embed = $('.media_embed', scope);
	if (typeof(embed.fitVids) !== 'undefined') {
		embed.fitVids();
	}

	// Legacy image class adding.
	$('.legacy-photo', scope).closest('.media-element-container').addClass('legacy-photo-container');

	// Make sure tooltips are loaded.
	window.ABR.ReOpenTip();

	// Re-bind the ctools-collapsible.
	if (typeof(Drupal.behaviors.CToolsCollapsible) !== 'undefined') {

		// Pass the HTML element to the Drupal stuff because we're using
		// two different versions of jQuery.
		Drupal.behaviors.CToolsCollapsible.attach(typeof(scope.get) !== 'undefined' ? scope.get(0) : scope);

	}

	// Re-bind the juicebox.
	if (typeof(Drupal.behaviors.juicebox) !== 'undefined') {
    Drupal.behaviors.juicebox.attach(typeof (scope.get) !== 'undefined' ? scope.get(0) : scope, Drupal.settings || {});
  }

	// Re-initialize the advertisements.
	window.ABR.Ads.resize();

  // Re-initialize spanish translations
  $(".translation-controls a" ).click(function() {
    var tab = $(this);
    tab.parents('.row').addClass('spanish-translated-article');

    if(tab.attr('id') === 'spanish-translation-control-label') {
      $('.spanish-translated-article .pane-node-body').hide();
      $('.spanish-translated-article .english-title').hide();
      $('.spanish-translated-article .spanish-translation').show();
    }

    if(tab.attr('id') === 'english-translation-control-label') {
      $('.spanish-translated-article .spanish-translation').hide();
      $('.spanish-translated-article .english-title').show();
      $('.spanish-translated-article .pane-node-body').show();
    }
  });
};

window.ABR.ArticleLoaded(document);

window.ABR.PreloadShareImages = function () {
	var images = ['/icons/social/twitter.svg', '/icons/social/facebook.svg', '/icons/social/pinterest.svg', '/icons/social/instagram.png', '/icons/social/rss.svg', '/icons/social/tiktok.svg', '/icons/social/youtube.svg'];
	var imageObjects = [];
	for (var i = 0; i < images.length; i++) {
		imageObjects[i] = new Image();
		imageObjects[i].src = Drupal.settings.abr_general.theme_path + '/img' + images[i];
	}
};
window.ABR.PreloadShareImages();

$(function () {
	$('[data-links-target]').each(function (index, element) {
		var $element = $(element);
		$element.find('a').attr("target", $element.attr("data-links-target"));
	});
  $('.tw-share-button').initialize( function(){
    var tweetText = $(this).html();
    var tweetHashtags = $(this).attr("data-hashtags");
    var buttonSize = $(this).attr("data-size");
    var dataHref = $(this).attr("data-href");
    var url = "url=" + ((typeof dataHref !== "undefined" && dataHref !== false)? dataHref: window.location.href);
    if (tweetText !== "") {
      url += "&text=" + encodeURIComponent(tweetText);
    }
    if (typeof tweetHashtags !== "undefined") {
      url += "&hashtags=" + tweetHashtags;
    }
    $(this).html($('<a/>').addClass("twitter-share-button").attr({"data-size":buttonSize,"href": "https://twitter.com/intent/tweet?"+ url}));
  });

  var resizeId;
  $(window).resize(function() {
    clearTimeout(resizeId);
    resizeId = setTimeout(doneResizing, 500);
  });

  $(window).trigger('resize');

  function doneResizing(){
    var contentOffset = parseFloat($(".footer-inner").offset().left);
    var socialMediaWidth = parseFloat($('.show-vertical').width());
    var fontSize = parseFloat($('html').css('font-size')) /2;
    //set social media position based on the main content with .5 space between.
    $('.show-vertical').css("left", contentOffset - socialMediaWidth - fontSize);
  }
});
