(function($) {

	var Scroll = function () {

		var lastScrollTop = 200;
		var delta = 50;
		var bottomThreshold = 100;
		var bottomThresholdBreakpoints = {
			small: 300,
			medium: 200,
			large: 100,
			default: 100
		};
		var bottomTriggered = false;
		var mode = 'none';
		this.events = {};
		this.scrollUp = [];
		this.scrollDown = [];
		this.scrollUpImmediate = [];
		this.scrollDownImmediate = [];
		this.scrollBottom = [];
		this.scrollTop = [];
		var self = this;

		this.events.scroll = function (event) {
			var st = $(this).scrollTop();
			if (Math.abs(lastScrollTop - st) <= delta) {
				return;
			}

			// Trigger the immediate handlers.
			if (st > lastScrollTop) {
				$.each(self.scrollDownImmediate, function (index, element) {
					element(st);
				});
			} else {
				$.each(self.scrollUpImmediate, function (index, element) {
					element(st);
				});
			}

			// Trigger the event handlers if we need to.
			if (st > lastScrollTop && mode !== 'down') { // Scroll Down
				mode = 'down';
				$.each(self.scrollDown, function (index, element) {
					element();
				});
			} else if (st < lastScrollTop && mode !== 'up') { // Scroll up
				mode = 'up';
				$.each(self.scrollUp, function (index, element) {
					element();
				});
			}
			lastScrollTop = st;

			// Check to see if we're at the top of the page.
			if (st < 100) {
				$.each(self.scrollTop, function (index, element) { element(); });
				$(document).trigger('scroll-top');
			}

			// Check to see if we're at the bottom of the page
			// and fire the necessary event handlers.
			var atBottom = st + $(window).height() > $(document).height() - bottomThreshold;
			if (atBottom && !bottomTriggered) {
				bottomTriggered = true;
				$.each(self.scrollBottom, function (index, element) { element(); });
				$(document).trigger('scroll-bottom');
			} else if (!atBottom) {
				bottomTriggered = false;
			}

			// Process each of the scroll triggers.
			$('[data-scroll-offset]').each(function (index, element) {
				if ($(element).attr('data-scroll-offset') < st) {
					$(element).show();
				} else {
					$(element).hide();
				}
			});

		};

		this.events.resize = function () {
			var breakpoint = Foundation.MediaQuery.current;
			if (bottomThresholdBreakpoints[breakpoint]) {
				bottomThreshold = bottomThresholdBreakpoints[breakpoint];
			} else {
				bottomThreshold = bottomThresholdBreakpoints.default;
			}
		};

	};

	Scroll.prototype.init = function () {

		// Listen to events.
		$(window).scroll(Foundation.util.throttle(this.events.scroll, 100));
		this.events.scroll.apply(document);

		$(window).on('resize', this.events.resize);
		setInterval(this.events.resize, 1000);

	};

	window.ABR.Scroll = new Scroll();

})(jQuery);
