//iframeLoad & popupCookie defined in first.js
(function($) {
  $("#submitNewsletter").load(function(){
    if (iframeLoad > 0){
      //we can't determine if the submission succeeded or failed due to cross domain constraints.
      //just show the customer the success message.
      $("#submitNewsletter", window.parent.document).hide();
      $("#newsletterFormText").html('Thank you for Signing Up for our Newsletter. Look forward to the best from America\'s Best Racing!');
      //set a cookie after submitting form to keep things from showing up again.
      $.cookie(popupCookie, 'submitted', {expires: (365), path: '/'});
    }

    //increment load run so above code only runs on a reload of the iframe
    iframeLoad++;
  });
})(jQuery);
