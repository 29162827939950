(function ($) {

	var Progress = function () {

		var has_zero = true;
		var self = this;
		self.target = null;
		self.events = {};
		self.progress_elements = null;
		self.height = 0;
		self.heightOffset = 0;

		self.events.scroll = function () {
			var st = $(document).scrollTop() - self.heightOffset;
			var percent = ((st / self.height) * 100);
			if (percent <= 0) { percent = 100; }
			if (percent <= 0 && !has_zero) {
				self.progress_elements.parent().addClass('no-transition');
				has_zero = true;
			}
			if (has_zero && percent > 0) {
				self.progress_elements.css('width', percent + '%');
				self.progress_elements.parent().removeClass('no-transition');
				has_zero = false;
			}
			self.progress_elements.css('width', percent + '%');
		};

		self.events.resize = function () {
			self.height = self.target.height();
			self.heightOffset = self.target.offset().top;
		};

	};

	Progress.prototype.init = function () {

		// Are there any progress elements on the page?
		this.progress_elements = $('[data-page-progress] > div');
		if (this.progress_elements.length > 0) {

			// Attach the target.
			this.target = $('main .pane-node-body').first();

			// Set the internal height.
			$(window).on('resize', Foundation.util.throttle(this.events.resize, 150));
			this.events.resize();

			// Make sure the height is recalculated on regular
			// intervals.
			setInterval(this.events.resize, 250);

			// Attach the scroll event.
			$(document).scroll(this.events.scroll);
			this.events.scroll();
			this.progress_elements.parent().addClass('no-transition');

		}

	};

	Progress.prototype.setTarget = function (target) {

		var self = this;
		self.target = $(target).first();

		// Call the refresh events and update the progress bar.
		self.events.resize();
		self.events.scroll();

	};

	window.ABR.Progress = new Progress();

})(jQuery);
