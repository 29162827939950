(function($) {
  Opentip.styles.sharetip = {
    fixed: true,
    stem: true,
    stemLength: 10,
    stemBase: 20,
    tipJoint: "middle left",
    borderWidth: 1,
    borderColor: "#999999",
    background: "#FFFFFF",
    shadow: true,
    shadowBlur: 0,
    hideDelay: 2
  };
  Opentip.defaultStyle = "sharetip";
})(jQuery);
