(function($) {
  $(".what-foal-button").click(function(){
    var answer = $(this).parents(".grid-container").find(".answer");
    if ($(this).hasClass("what-foal-active")){
      $(this).removeClass("what-foal-active");
      answer.removeClass("answer-active");
    } else {
      $(this).addClass("what-foal-active");
      answer.addClass("answer-active");
    }
  });

  $(".true-false-button").click(function(){
    $(".question").hide();
    $("." + $(this).find(".answer").text().toLowerCase() + "-answer").show();
    $(".explanation").show();
    $(this).parent().find(".true-false-button").css("display", "none");
  });

  $(".play-button").click(function(){
    $(this).next(".media_embed").click();
  });

  $("video").parent().click(function() {
    $(this).parent().find(".play-button").hide();
    var video = $(this).find("video");
    video.attr("controls","controls");
    video.get(0).play();
    //trun off this handler for the video that is now playing as it is no longer needed
    $(this).off("click");
  });
})(jQuery);
