(function($) {

	var LinkSelect = function () {

		var self = this;
		self.events = {};

		self.events.changed = function (e) {
			window.location = $(this).find('option:selected').val();
		};

	};

	LinkSelect.prototype.init = function () {
		var self = this;
		$('select[data-link-select]').each(function (index, element) {
			$(element).change(self.events.changed);
		});
	};

	window.ABR.LinkSelect = new LinkSelect();

})(jQuery);
