(function($) {

	var Infinite = function () {

		var self = this;
		self.events = {};

		self.events.scrollBottom = function () {
			$('[data-infinite]').each(function (index, infinite) {

				var $infinite = $(infinite);

				// Get the config and path from the data attributes on the
				// element.
				var config = JSON.parse($infinite.attr('data-infinite-options'));
				var path = $infinite.attr('data-infinite');

				// If the infinite doesn't have any more data to load, don't
				// do anything.
				if ($infinite.data('infinite-nomoredata')) { return true; }

				// Get the page from the config and update it on the element.
				config.page = $infinite.data('infinite-page') || 0;
				config.page++;
				$infinite.data('infinite-page', config.page);

				// Show the loader.
				$infinite.find('.infinite-loader').addClass('visible');

				// Make the AJAX call to fetch the extra information, then
				// call the function to reload all the JS elements.
				$.get(path, config, function (data) {

					// Use a setTimeout to give the ArticleLoaded event some
					// time to fire before we hide the loader.
					setTimeout(function () {
						$infinite.find('.infinite-loader').removeClass('visible');
					}, 0);

					// If there isn't any data, mark it as nomoredata.
					if (!data) {
						$infinite.data('infinite-nomoredata', true);
						return;
					}

					$infinite.find('.infinite-loader').before(data);
					window.ABR.ArticleLoaded($infinite);

				}, 'html');

			});
		};

	};

	Infinite.prototype.init = function () {

		// Attach the scrollBottom event.
		ABR.Scroll.scrollBottom.push(this.events.scrollBottom);

		// For each of the infinite loaders, attach the loader to
		// the bottom of the element.
		$('[data-infinite]').each(function (index, infinite) {
			var $infinite = $(infinite);
			$infinite.append('<div class="infinite-loader"><div class="ball-pulse-sync"><div></div><div></div><div></div></div></div>');
		});

	};

	window.ABR.Infinite = new Infinite();

})(jQuery);
