(function ($) {

	var ScrollTo = function () {

		this.events = {};
		var self = this;

		self.events.click = function (e) {
			e.preventDefault();
			var target = $(this).attr('href');
			var $target = $(target);
			if ($target.length <= 0) { return; }
			$.scrollTo($target, { offset: -200, duration: 250 });
			if ($(this)[0].hasAttribute('data-click')) {
				$target.click();
			}
		};

	};

	ScrollTo.prototype.init = function () {

		var self = this;
		$('a[href^=\'#\']').not('[data-scrollto-handled]').each(function (index, link) {
			var $link = $(link);
			$link.attr('data-scrollto-handled', '');
			if ($link.attr('role') === 'tab') { return; }
			$link.click(self.events.click);
		});

	};

	window.ABR.ScrollTo = new ScrollTo();

})(jQuery);
