(function($) {

	var ImageLoader = function () {

		var self = this;
		self.events = {};
		self.methods = {};

		self.methods.process = function ($loader) {

			$loader.addClass('image-loader-processed');
			var percentage = ($loader.data('ratio') || 1) * 100;
			$loader.css('padding-bottom', percentage.toString() + '%');

			// Grab the image source.
			var imageSource = $('[data-image]', $loader).first().attr('src');
			var image = new Image();
			image.onload = function () {
				$('[data-image]', $loader).addClass('loaded');
			};
			image.src = imageSource;

		};

	};

	ImageLoader.prototype.init = function () {
		var self = this;
		$('.image-loader').not('.image-loader-processed').each(function (index, element) {
			self.methods.process($(element));
		});
	};

	window.ABR.ImageLoader = new ImageLoader();

})(jQuery);
