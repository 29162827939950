(function($) {

	var FancyCheckbox = function () {

		var self = this;
		self.methods = {};

		self.methods.updateClass = function (element, checkbox) {
			var $element = $(element);
			var $checkbox = $(checkbox);
			if ($checkbox.is(':checked')) {
				$element.removeClass('unchecked').addClass('checked');
			} else {
				$element.removeClass('checked').addClass('unchecked');
			}
		};

	};

	FancyCheckbox.prototype.init = function () {
		this.fancy();
	};

	FancyCheckbox.prototype.fancy = function () {
		var self = this;
		$('[data-fancy-checkbox]').not('[data-fancy-checkbox-processed]').each(function (index, element) {
			var $element = $(element);
			var checkbox = $element.find('input[type="checkbox"]').first();
			checkbox.click(function () {
				self.methods.updateClass(element, checkbox);
			});
			self.methods.updateClass(element, checkbox);
			$element.attr('data-fancy-checkbox-processed', '');
		});
	};

	window.ABR.FancyCheckbox = new FancyCheckbox();

})(jQuery);
