(function($) {

	var Expandable = function () {

		var getMode = function (element) {
			if ($(element).hasClass('expandable')) {
				return $(element).attr('data-mode');
			} else {
				return $(element).parents('.expandable').first().attr('data-mode');
			}
		};

		var calculateMaxHeight = function (element) {
			var maxHeightBefore = $(element).css('max-height');
			var transitionBefore = $(element).css('transition');
			$(element).css('transition', 'none');
			var height = $(element).css('max-height', 'none').outerHeight();
			$(element).css('transition', transitionBefore);
			$(element).css('max-height', maxHeightBefore);
			return height;
		};

		var calculateHeight = function (element, open) {
			if (getMode(element) === 'summary') {
				if (open) {
					return calculateMaxHeight(element);
				} else {
					return 0;
				}
			} else if (getMode(element) === 'truncate') {
				if (open) {
					return calculateMaxHeight(element);
				} else {
					var parentColumn = $(element).parents('.columns').first();
					var siblingColumn = parentColumn.siblings('.columns').first();
					while (!siblingColumn.length) {
						if (parentColumn.parents('.columns').length) {
							parentColumn = parentColumn.parents('.columns').first();
							siblingColumn = parentColumn.siblings('.columns').first();
						} else {
							return 0;
						}
					}
					var height = 0;
					if (siblingColumn.length) {
						parentColumn.hide();
						height = siblingColumn.outerHeight();
						parentColumn.show();
					}
					return height;
				}
			}
		};

		var transitionHeight = function ($element, open) {
			var height = calculateHeight($element.find('.full'), open);

			setTimeout(function () {
				$element.find('.full').css('max-height', height);

        // TODO: Figure out how to do this properly. This is temporary hack... Fingers crossed.
        $('html').removeClass('noscroll');
			}, 25);
		};

		this.events = {};
		var self = this;

		self.events.on = function () {
			transitionHeight($(this), true);
		};

		self.events.off = function () {
			transitionHeight($(this), false);
		};

		self.events.init = function (element) {
			var $element = $(element);
			if (getMode($element) === 'truncate') {
				var height = calculateHeight($element, false) - $element.find('.toggle').outerHeight();
				if (height > calculateMaxHeight($element.find('.full'))) {
					$element.find('.toggle').hide();
				} else {
					$element.find('.toggle').show();
				}
				$element.find('.full').css('max-height', calculateHeight($element, false) - $element.find('.toggle').outerHeight());
			}

			// If the expand-for property passes the current breakpoint.
			if (Foundation.MediaQuery.atLeast($element.attr('data-expand-for'))) {
				$element.find('.toggle').hide();
				transitionHeight($element, true);
			} else {
				$element.find('.toggle').show();
				transitionHeight($element, $element.hasClass('full-visible'));
			}
		};

	};

	Expandable.prototype.init = function () {

		var self = this;
		$('.expandable').each(function (index, element) {
			$(element).on('on.zf.toggler', self.events.on);
			$(element).on('off.zf.toggler', self.events.off);
			self.events.init(element);
			$(element).parents('[data-tabs-content]').siblings('[data-tabs]').first()
				.on('change.zf.tabs', function () { self.events.init(element); });
		});

		$(window).on('resize', Foundation.util.throttle(function () {
			// self.refresh(); Removed to solve scrolling issues on iOS devices
    }, 250));

	};

	Expandable.prototype.refresh = function () {

		var self = this;
		$('.expandable').each(function (index, element) {
			self.events.init(element);
		});

	};

	window.ABR.Expandable = new Expandable();

})(jQuery);
