(function($) {

	var initialized = false;

	var Ads = function () {

		var resizeTimer;
		this.events = {};
		this.slots = window.AdSlots;
		var self = this;

		self.events.resize = function () {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(self.resize, 250);
		};

	};

	Ads.prototype.resize = function () {
		try {
			googletag.pubads().refresh(this.slots);
		} catch (e) {
			setTimeout(this.resize, 100);
		}
	};

	Ads.prototype.init = function () {

		var self = this;

		// Only initialize once.
		if (initialized === true) { return; }
		initialized = true;

		// Listen for the resize event.
		$(window).on('resize', self.events.resize);

	};

	window.ABR.Ads = new Ads();

})(jQuery);
