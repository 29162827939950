/*------------------------------------*\
		 Profile Hero Gallery
 \*------------------------------------*/
var slide_count = $('.gallery-slider .slide').size();
$('.gallery-slider').slick({
	dots: false,
	autoplay: true,
	autoplaySpeed: 5000,
	initialSlide: slide_count-1,
	pauseOnHover: false,
	fade: true,
	zIndex: 50,
});

/**
 * A utility function to find all URLs - FTP, HTTP(S) and Email - in a text string
 * and return them in an array.  Note, the URLs returned are exactly as found in the text.
 *
 * From: http://stackoverflow.com/questions/4504853/how-do-i-extract-a-url-from-plain-text-using-jquery
 *
 * @param text
 *            the text to be searched.
 * @return an array of URLs.
 */
function findUrls( text )
{
	var source = (text || '').toString();
	var urlArray = [];
	var url;
	var matchArray;

	// Regular expression to find FTP, HTTP(S) and email URLs.
	var regexToken = /(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)|((mailto:)?[_.\w-]+@([\w][\w\-]+\.)+[a-zA-Z]{2,3})/g;

	// Iterate through any URLs in the text.
	while( (matchArray = regexToken.exec( source )) !== null )
	{
		var token = matchArray[0];
		urlArray.push( token );
	}

	return urlArray;
}

// Gallery loader.
$('.profile-gallery-loader').each(function (index, loader) {
	var slide_count = $('div.slide', $(loader)).length;
	var loaded = 0;
	$('div.slide', loader).each(function (index, slide) {
		var img = new Image();
		$(img).on('load', function () {
			loaded++;
			if (loaded >= slide_count) {
				$(loader).removeClass('loading');
				$(loader).find('.overlay-std').removeClass('visible');
			}
		});
		var bg_url = $(slide).css('background-image');
		bg_url = /url\((['"]?)(.*)\1\)/.exec(bg_url);
		bg_url = bg_url ? bg_url[2] : "";
		img.src = bg_url;
	});
});


/*------------------------------------*\
		 Current Connections
 \*------------------------------------*/
$('.center-slider').slick({
	centerMode: true,
	centerPadding: '55px',
	slidesToShow: 3,
	variableWidth: true,
	responsive: [
		{
			breakpoint: 9999, // Only enable slick on mobile
			settings: "unslick"
		},
		{
			breakpoint: 780,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '40px',
				slidesToShow: 1
			}
		}
	]
});


/*------------------------------------*\
		 Top Four Leaderboard
 \*------------------------------------*/
$('.leaderboard-list').slick({
	centerMode: true,
	centerPadding: '55px',
	slidesToShow: 3,
	variableWidth: false,
	infinite: false,
	initialSlide: 0,
	responsive: [
		{
			breakpoint: 9999, // Only enable slick on mobile
			settings: "unslick"
		},
		{
			breakpoint: 1025,
			settings: {
				centerMode: false,
				slidesToShow: 3,
				variableWidth: true,
				arrows: false,
			}
		},
		{
			breakpoint: 641,
			settings: {
				arrows: false,
				centerMode: true,
				centerPadding: '55px',
				slidesToShow: 1,
				variableWidth: true,
			}
		}
	]
});

/*------------------------------------*\
 Sponsor Slider - Footer
 \*------------------------------------*/

$('.sponsor-slider').slick({
  dots: false,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }
  ]
});


/*------------------------------------*\
 Video Slideshow
 \*------------------------------------*/
$('.video-slider').each(function (index, element) {
	var $slider_container = $(element);
	var $large_slider = $('.large-slider', $slider_container);
	var $thumbnail_slider = $('.thumbnails', $slider_container);
	$large_slider.slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: $thumbnail_slider,
		responsive: [
			{
				breakpoint: 640,
				settings: { dots: true }
			}
		]
	});
	$thumbnail_slider.slick({
		slidesToShow: 6,
		slidesToScroll: 6,
		asNavFor: $large_slider,
		focusOnSelect: true
	});
});
