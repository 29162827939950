(function($) {

	var Menu = function () {



	};

	Menu.prototype.init = function () {

		// Prepare variables.
		var $sidebar_menu = $('aside#sidebar .menu');
		var $links = $('.header-wrapper > a.expand-button', $sidebar_menu);

		// Register the click event on each of the links.
		$links.each(function (index, element) {
			var $element = $(element);
			var $expandable = $element.parents('.expandable').first();
			var $menu = $('.menu', $expandable).first();
			$menu.attr('data-height', $menu.outerHeight(true));
			$menu.css('max-height', 0);
			$element.click(function (e) {
				e.preventDefault();
				$expandable.toggleClass('expanded');
				if ($expandable.hasClass('expanded')) {
					$menu.css('max-height', $menu.attr('data-height') + 'px');
				} else {
					$menu.css('max-height', 0);
				}
			});
		});

	};

	window.ABR.Menu = new Menu();

})(jQuery);
